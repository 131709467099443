<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="教师ID">
            <el-input clearable
                      style="width:100%"
                      placeholder="教师姓名或手机"
                      v-model="form.keyword"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="学校"
                        prop="school_id">
            <el-select v-model="form.edu_school_id"
                       clearable
                       filterable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in schoolList"
                         :key="item.school_id"
                         :label="item.school_name"
                         :value="item.school_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            default-time="23:59:59"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight + 45"
              :summary-method="getSummaries"
              :data="tableData"
              show-summary
              style="width: 100%">
      <!-- 教师ID、手机、用户名、批改试卷总数、批改题目总数 -->
      <el-table-column prop="assist_id"
                       align="center"
                       label="教师ID" />
      <el-table-column prop="edu_user_mobile"
                       align="center"
                       label="手机" />
      <el-table-column prop="edu_user_name"
                       align="center"
                       label="用户名" />
      <el-table-column prop="count_papers"
                       align="center"
                       label="批改试卷总数" />
      <el-table-column prop="count_question"
                       align="center"
                       label="批改题目总数" />
      <el-table-column prop="error_count"
                       align="center"
                       label="批改错误数" />
      <el-table-column prop="name"
                       align="center"
                       width="200"
                       label="操作">
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="改卷详情"
                        placement="top-start">
              <img src="@/assets/icons/icon-查看@2x.png"
                   @click="showForm(row)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { now_month_time, nowDateTime } from '@/utils/date.js'
import { getStartSchool } from '@/api/school.js'

export default {
  data () {
    return {
      tableData: [],
      schoolList: [],
      value1: '',
      form: {
        keyword: '',
        begin_time: now_month_time(),
        end_time: nowDateTime(),
        edu_school_id: ''
      },
      all_sum: {},
      page: 1,
      size: 10,
      totalElements: 0,
    }
  },
  mounted () {
    this.getSchoolList()

  },
  methods: {
    async getSchoolList () {
      const { data } = await getStartSchool()
      this.schoolList = data.list
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/correct',
        method: 'get',
        params: form
      }).then(res => {
        this.all_sum = res.data.all_sum
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    exportExcel () {
      this.$http({
        url: '/api/v2/stats/correct_export',
        method: 'get',
        responseType: "blob",
        params: this.form
      }).then(res => {
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `批改统计列表.xlsx`
        a.click()
      })
    },
    showForm (row) {
      let form = {
        end_time: this.form.end_time,
        begin_time: this.form.begin_time,
        edu_user_id: row.assist_id
      }
      this.$router.push(`/changeAdmin/paperlist?form=${JSON.stringify(form)}`)
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    // 统计行
    getSummaries () {
      const sums = [];
      sums[0] = '所有教师合计'
      sums[1] = ''
      sums[2] = ''
      sums[3] = this.all_sum.paper_sum
      sums[4] = this.all_sum.question_sum
      sums[5] = this.all_sum.error_count
      sums[6] = ''

      return sums;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>